export function addModification() {
    const addModificationBtns = document.querySelectorAll('.__add_mod_btn');
    if(addModificationBtns.length > 0) {
        addModificationBtns.forEach(btn=>{
            const modification = btn.getAttribute('data-mod');
            btn.addEventListener('click',()=>{
                const span = btn.parentNode.querySelector('span');
                if(span.innerHTML == 'Удалить' && modification !== 'packing') {
                    btn.checked = false;
                    span.innerHTML = 'Выбрать';
                }
                if(btn.checked) {
                    span.innerHTML = 'Удалить';
                } else {
                    span.innerHTML = 'Выбрать';
                };
                addModificationBtns.forEach(unactive=>{
                    const UnActmodification = unactive.getAttribute('data-mod');
                    const unActiveSpan = unactive.parentNode.querySelector('span');
                    if (modification == 'branding' && 
                        UnActmodification == modification 
                        && unactive !== btn || 
                        modification == 'packing' && 
                        UnActmodification == modification && 
                        unactive !== btn) {
                        unActiveSpan.innerHTML = 'Выбрать';
                    };
                });
                
                checkForChecked(modification);
            });
        });
    };
}

function checkForChecked(modification) {
    const addModificationBtns = document.querySelectorAll('.__add_mod_btn');
    const finalArray = [];
    const nameArray = [];
    let image = '';
    let fullPrice = 0;
    addModificationBtns.forEach(btn=>{
        if(btn.checked && btn.getAttribute(`data-mod`) == modification) {
            const id = Number(btn.getAttribute('data-id'));
            if(!!btn.dataset.image) image = btn.dataset.image;
            const name = btn.getAttribute('data-name');
            fullPrice = parseInt(fullPrice) + parseInt(btn.getAttribute('data-price'));
            finalArray.push(id);
            nameArray.push(name);
        };
    });
    const title = document.querySelector(`.__add_mod_title[data-mod="${modification}"]`);
    if(title) {
        if(finalArray.length > 0) {
            title.classList.add('active');
        } else {
            title.classList.remove('active');
        }
        
        if(title.hasAttribute('name') && nameArray.length > 0) {
            title.innerHTML = nameArray;
        } else if(title.hasAttribute('name') && nameArray.length == 0) {
            title.innerHTML = '';
        }
        if(title.hasAttribute('data-price')) title.setAttribute('data-price', fullPrice);
        title.setAttribute('data-value', finalArray);
    }
    const newImage = document.querySelector(`.__add_mod_image[data-image=${modification}]`);
    if(newImage) {
        if(image == '' && modification == 'inserts') image = '/static/assets/images/bundle-card/noInserts.png';
        if(image == '' && modification == 'branding') image = '/static/assets/images/bundle-card/noBranding.png';
        newImage.src = image;
    }
}