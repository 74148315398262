export function imageModal(section) {
    const openModalBtns = section ? section.querySelectorAll('.open-modal[data-type-modal="image-modal"]') : document.querySelectorAll('.open-modal[data-type-modal="image-modal"]');
    const modal = document.querySelector('.modal[data-type="image-modal"]');
    if(openModalBtns.length === 0 || !modal) return;
    openModalBtns.forEach(elem=>{
        const images = elem.querySelectorAll('img');
        const videos = elem.querySelectorAll('video');
        let container = modal.querySelector('.__package-images');
        elem.addEventListener('click', () => {
            container.innerHTML = '';

            const fragment = document.createDocumentFragment();

            let imageSlides = '';
            images.forEach(image => { 
                imageSlides += `
                    <div class="swiper-slide">
                        <img src="${image.src}" alt="package-image">
                    </div>
                `;
            });

            let videoSlides = '';
            videos.forEach(video => {
                videoSlides += `
                    <div class="swiper-slide">
                        <video src="${video.src}" autoplay loop></video>
                    </div>
                `;
            });

            const slider = document.createElement('div');
            slider.classList.add('swiper', 'package-image_slider');
            slider.innerHTML = `
                <div class="swiper-wrapper">
                    ${imageSlides}
                    ${videoSlides}
                </div>
            `;

            fragment.appendChild(slider);

            container.appendChild(fragment);

            const packageImageSlider = new Swiper('.package-image_slider', {
                slidesPerView: 1,
                navigation: {
                    nextEl: '.package-image_slider__next',
                    prevEl: '.package-image_slider__prev',
                },
                mousewheel: {
                    forceToAxis: true,
                },
            });
        });
    });
}