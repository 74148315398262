import { getData } from "./utils.js";

export function productCard() {
    const productCards = document.querySelectorAll('.product-card_size_small');
    if(productCards.length > 0) {
        productCards.forEach(card=>{
            const candySetBtns = card.querySelectorAll('.__candy_set__btn');
            candySetBtns.forEach(btnChecked=>{
                btnChecked.addEventListener('click',()=>{
                    candySetBtns.forEach(btn=>{
                        const labelsForInput = card.querySelectorAll(`label[for="${btn.id}"]`);
                        if(btn.checked) {
                            getData(`/api/packing-prices/${btn.dataset.packing}/?set=${btn.dataset.id}`)
                            .then(res=>{
                                const card = btn.closest('.product-card');
                                const cardTitle = card.querySelector('.product-card__accordion__header__title-block');
                                const content = card.querySelector('.product-card__accordion__content');
                                content.innerHTML = '';
                                res.prices.forEach((info, index)=>{
                                    if(index == 0) {
                                        cardTitle.innerHTML = `
                                            <span>${info.price} ₽/шт.</span>
                                            от ${info.quantity} шт.
                                        `;
                                    } else {
                                        let line = document.createElement('div');
                                        line.classList.add('product-card__accordion__content__line');
                                        line.innerHTML = `
                                            <span>${info.price} ₽/шт.</span>
                                            от ${info.quantity} шт.
                                        `;
                                        content.appendChild(line);
                                    };
                                });
                            });
                            labelsForInput.forEach(label=>{
                                label.classList.add('button_outline_active');
                            });
                        } else {
                            labelsForInput.forEach(label=>{
                                label.classList.remove('button_outline_active');
                            });
                        }
                    });
                });
            });
        });
    };
}