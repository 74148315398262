export function cookie() {
    document.addEventListener('DOMContentLoaded', function () {
        const cookieElem = document.querySelector('.cookie-notification');
    
        if (!document.cookie.includes("warning=true")) {
            if(cookieElem) {
                cookieElem.style.transform = ('scale(1)');
                cookieElem.style.opacity = 1;
            }
        }
    
        if (cookieElem) {
            const apply = document.querySelector(".cookie-notification__accept");
            const deny = document.querySelectorAll(".cookie-notification__decline");
            if(apply) {
                apply.addEventListener("click", () => {
                    cookieElem.style.transform = null;
                    cookieElem.style.opacity = null;
                    document.cookie = "warning=true; max-age=2592000; path=/";
                });
            }
            if(deny.length > 0) {
                deny.forEach((btn) => {
                    btn.addEventListener("click", () => {
                        cookieElem.style.transform = null;
                        cookieElem.style.opacity = null;
                    })
                })
            }
        }
    });
    
}

export const getCookiesBasket = () => {
    return decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)basket\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
}
export function getBasketFromCookie() {
    const basketCookies = getCookiesBasket();
    return basketCookies ? JSON.parse(basketCookies) : [];
}
export function addProductInBasket(id, quantity, candySet, inserts, branding) {
    let basket = getBasketFromCookie();
    basket.push({
        "packing": id,
        "quantity": quantity,
        "candySet": candySet,
        "inserts": inserts,
        "branding": branding
    });
    setBasketToCookie(basket);
}
export function setBasketToCookie(basket) {
    setCookie('basket', JSON.stringify(basket), { secure: true });
}


export function getGiftItemsCookie() {
    const giftItemsCookie = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)gift-items\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
    return giftItemsCookie ? JSON.parse(giftItemsCookie) : [];
}
export function setGiftItemsCookie(giftItems) {
    setCookie('gift-items', JSON.stringify(giftItems), { secure: true });
}


export function setCookie(name, value, options = {}) {
    options = {
        path: '/',
      ...options
    };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
};