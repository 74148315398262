export function mouseToMove() {
    const mouseToMoveElems = document.querySelectorAll('.__move_to_mouse');
    mouseToMoveElems.forEach(movingElem=>{
        if(window.innerWidth > 768) {

            let depth = 100;
            if(movingElem.getAttribute('data-depth')) depth = Number(movingElem.getAttribute('data-depth'));

            const positionCursor = (e)=> {
                const mouseY = e.clientY;
                const mouseX = e.clientX;
                
                const elemRect = movingElem.getBoundingClientRect();
                
                const elemY = elemRect.y;
                const elemX = elemRect.x;

                movingElem.style.transform = `translate(${-(elemX - mouseX) / depth}px, ${-(elemY - mouseY) / depth}px)`;
            };
            window.addEventListener('mousemove', positionCursor);
        }
    });
}