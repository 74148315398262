import { openModal } from "../base/modals.js";
import { calculateTotalPrice } from "../pages/main/calcBundleCard.js";
import { getCurrentQuery, getData } from "../utils.js";
import { imageModal } from "./imageModal.js";


export function consistModal() {
    const modalBody = document.querySelector('.__consist_modal__body');
    if(!modalBody) return;
    const modal = document.querySelector('[data-type="consist-modal"]');
    const modalInset = modal.querySelector('.modal__inset');

    modalInset.addEventListener('click',()=>{
        window.location.reload();
    });

    const currentQuiries = getCurrentQuery();

    const cards = modalBody.querySelectorAll('.gift-compositions-section__menu__item');
    const consistCard = document.querySelector('.__consist_card');
    cards.forEach(card=>{
        const cardId = card.getAttribute('data-id');
        if(cardId == currentQuiries['set']){
            card.classList.add('show');
            const findBodyElem = card.querySelector('.accordion-item__body');
            findBodyElem.style.maxHeight = findBodyElem.scrollHeight * 2 + "px";
            consistCard.classList.remove('error');
            setTitle(card);
            calculateTotalPrice();
            showCandies(cardId);
        };

        card.addEventListener('click',()=>{
            setNewUrl(cardId);
            showCandies(cardId);
        });
    });
}

function setNewUrl(id) {
    const state = { additionalInformation: 'Replace State' };
    const title = 'Replace State Example';
    let url = `/?set=${id}`;

    const currentPacking = getCurrentQuery()['packing'];
    if(currentPacking) url = `/?packing=${currentPacking[0]}&set=${id}`;
    history.replaceState(state, title, url);
}

function setTitle(item) {
    const modification = item.getAttribute('data-mod');
    const modTitle = document.querySelector(`.__add_mod_title[data-mod="${modification}"]`);
    const modImage = document.querySelector(`.__add_mod_image[data-image="${modification}"]`);
    if(modTitle.getAttribute('name') == '') modTitle.innerHTML = item.querySelector('.__item__title').innerHTML;
    modTitle.setAttribute('data-value', item.getAttribute('data-id'));
    modTitle.setAttribute('data-price', item.getAttribute('data-price'));
    if(!!item.dataset.image) modImage.src = item.dataset.image;
}

function showCandies(id) {
    getData(`/api/candy-set/?id=${id}`)
    .then(res=>{
        const modalBody = document.querySelector('.__consist_modal__body');

        const packedInfoBlock = modalBody.querySelector('.__packed');
        const unpackedInfoBlock = modalBody.querySelector('.__unpacked');

        const results = res.results[0];
        const packedFragment = document.createDocumentFragment();

        results['packed_candies'].forEach(elem=>{
            createCard(elem.candy, elem.quantity, packedFragment);
        });
        const unpackedFragment = document.createDocumentFragment();
        results['unpacked_candies'].forEach(elem=>{
            createCard(elem.candy, elem.quantity, unpackedFragment);
        });

        packedInfoBlock.innerHTML = ``;
        unpackedInfoBlock.innerHTML = ``;
        packedInfoBlock.appendChild(packedFragment);
        unpackedInfoBlock.appendChild(unpackedFragment);

        imageModal(packedInfoBlock);
        imageModal(unpackedInfoBlock);
    });
}

function createCard(elem, quantity, fragment) {
    const div = document.createElement('div');
    div.classList.add('candy-card', 'candy-card_pop-up');

    const photo = elem.photo ? elem.photo : '/static/assets/images/stub.png';

    div.innerHTML = `
        <div class="candy-card__image-block open-modal" data-type-modal="image-modal">
            <img src="${photo}" alt="Candy image">
        </div>
        <div class="candy-card__info-block">
            <div class="candy-card__title-block">
                <span>${elem.brand}</span>
                ${quantity} шт.
            </div>
            <span>${elem.name}</span>
        </div>
    `;
    fragment.appendChild(div);

    const button = div.querySelector('.open-modal');
    openModal(button);
}