export function header() {

    document.addEventListener('DOMContentLoaded', () => {
        const cityButton = document.getElementById('cityButton');
        const cityButtonMobile = document.getElementById('cityButtonMobile');
        const citySelector = document.getElementById('citySelector');
        const cityLinks = citySelector.querySelectorAll('.__link_city');
    
        if(cityButton) {
            cityButton.onclick = () => {
                citySelector.classList.toggle('show');
            };
            cityButtonMobile.onclick = () => {
                citySelector.classList.toggle('show');
            };
        };
        
        cityLinks.forEach(link=>{
            if(link.dataset.domain == `${window.location.origin}/`) {
                cityButton.querySelector('span').innerText = link.innerText;
                link.classList.add('active');
            };
        });
    });



    const stickyHeader = document.getElementById('sticky-header');
    const stickyNav = document.getElementById('page-nav-sticky');
    let prevScrollPos = window.pageYOffset;
    window.onscroll = function() {
        const currentScrollPos = window.pageYOffset;

        if (prevScrollPos > currentScrollPos) {
            if(stickyHeader) {
                stickyHeader.classList.remove('sticky-hidden');
            }
            if (stickyNav) {
                stickyNav.classList.remove('sticky-hidden');
            }
        } else {
            if(stickyHeader) {
                stickyHeader.classList.add('sticky-hidden');
            }
            if (stickyNav) {
                stickyNav.classList.add('sticky-hidden');
            }
        }

        prevScrollPos = currentScrollPos;
    }

    const closeTopHeaderBtn = document.querySelector('.__close__top-header');
    if(closeTopHeaderBtn) {
        closeTopHeaderBtn.addEventListener('click',()=>{
            const topHeader = document.querySelector('.header-section__top-header');
            topHeader.style.maxHeight = 0;
        });
    }

    const openMenuHeaderBtn = document.querySelector('.__open-menu__btn');
    if(openMenuHeaderBtn) {
        openMenuHeaderBtn.addEventListener('click',()=>{
            const active = '__open-menu__btn_active';
            if(openMenuHeaderBtn.classList.contains(active)) {
                insetHide();
            } else {
                openMobileMenu();
            }
        });
    
    }
    // function docOverflowHidden() {
    //     document.documentElement.style.overflow = "hidden";
    //     stickyHeader.style.position = "fixed";
    //     stickyHeader.classList.remove('sticky-hidden');
    // }
    // function docOverflowRemove() {
    //     document.documentElement.style.removeProperty('overflow');
    //     stickyHeader.style.position = "sticky";
    // }

    // const inset = document.querySelector('.__inset');
    // const openMenuBtn = document.querySelectorAll('.open-some-menu');
    // const allMenus = document.querySelectorAll('.some-menu');
    // const headerNav = document.getElementById('header__nav');

    

    // openMenuBtn.forEach((el) => {
    //     const getDataOpen = el.getAttribute('data-open')
    //     el.addEventListener('click',() => {

    //         allMenus.forEach((e) => {
    //             e.style.maxHeight = '0';
    //             e.style.opacity = 0;
    //         })
            

    //         if (el.classList.contains('active')) {
    //             docOverflowRemove()
    //             headerNav.classList.remove('non-active');
    //             inset.classList.remove('show');
    //             el.classList.remove('active');
    //         } else {
    //             docOverflowHidden()
    //             headerNav.classList.add('non-active');
    //             inset.classList.add('show');
    //             allMenus.forEach((evt) => {
    //                 const getMenuData = evt.getAttribute('data-menu');
    //                 if(getMenuData == getDataOpen) {
    //                     evt.style.maxHeight = evt.scrollHeight * 2 + "px";
    //                     evt.style.opacity = 1;
    //                 }
    //             })

    //             openMenuBtn.forEach((e) => {
    //                 e.classList.remove('active');
    //             })
    //             el.classList.add('active');
    //         }
    //     })
    // })


    
    const inset = document.querySelector('.__inset');
    if(inset) {
        inset.addEventListener('click',()=>{
            insetHide();
        });
    }
}

function openMobileMenu() {
    const openMenuHeaderBtn = document.querySelector('.__open-menu__btn');
    const mobileMenu = document.querySelector('.header-section__mobile-menu');

    const active = '__open-menu__btn_active';

    openMenuHeaderBtn.classList.add(active);
    mobileMenu.style.maxHeight = mobileMenu.scrollHeight + 'px';
    insetShow()
}
function closeMobileMenu() {
    const openMenuHeaderBtn = document.querySelector('.__open-menu__btn');
    const mobileMenu = document.querySelector('.header-section__mobile-menu');

    const active = '__open-menu__btn_active';

    openMenuHeaderBtn.classList.remove(active);
    mobileMenu.style.maxHeight = null;
}


function insetShow() {
    const stickyHeader = document.getElementById('sticky-header');
    const main = document.querySelector('main');

    main.style.paddingTop = `${stickyHeader.scrollHeight}px`;
    stickyHeader.style.position = 'fixed';

    const inset = document.querySelector('.__inset');
    inset.classList.add('__inset_show');
    document.documentElement.style.overflow = 'hidden';
}
function insetHide() {
    const stickyHeader = document.getElementById('sticky-header');
    const main = document.querySelector('main');

    main.style.paddingTop = null;
    stickyHeader.style.position = null;

    const inset = document.querySelector('.__inset');
    inset.classList.remove('__inset_show');
    document.documentElement.style.overflow = null;

    closeMobileMenu();
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { header } from "./путь/к/файлу/header.js";

// Активация: header();