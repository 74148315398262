export function copyDetails() {
    const copyBtns = document.querySelectorAll('.__copy_elem_btn');
    if(copyBtns.length > 0) {
        copyBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                const copyElems = document.querySelectorAll('.__copy_elem');
                let allValues = '';

                copyElems.forEach(elem=>{
                    const key = elem.querySelector('.__copy_elem_key').innerText;
                    const value = elem.querySelector('.__copy_elem_value').innerText;

                    const keyValueStr = key + ': ' + value + '; ';
                    allValues = allValues + keyValueStr;
                });

                writeClipboardText(allValues);
            });
        });
    };
}

async function writeClipboardText(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error(error.message);
    }
}