import { candySets } from './candySets.js';
import { addModification } from './addModification.js';
import { getCardPrice } from './getCardPrice.js';

function init() {
    candySets();
    addModification();
    getCardPrice();
}

export {
    init   
}