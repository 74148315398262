export function calendar() {
    let clearBtn = {
        content: `
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.14453 5L15.1445 15M15.1445 5L5.14453 15" stroke="#610BD9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
        className: 'clear-btn',
        onClick: (dp) => {
            dp.clear();
        }
    }
    new AirDatepicker('#calendar', {
        dateFormat: 'dd.MM.yyyy',
        autoClose: false,
        minDate: new Date(),
        dateDelimiter: '.',
        buttons: ['today', clearBtn]
    });
}