import { getBasketFromCookie, setBasketToCookie } from "../../cookies.js";
import { createBasketCard } from "./createBasketCard.js";
import { cardDeletions } from "./deleteBasketCards.js";

export async function showCards() {
    const currentUrl = window.location.pathname;
    if(currentUrl.includes('basket')) {
        const basket = getBasketFromCookie();
        if(basket.length > 0) {
            const container = document.querySelector('.basket-section__info-block');
            let lastElemId = '';
            basket.forEach((elem, index)=>{
                if(elem.id) {
                    lastElemId = elem.id
                } else if(lastElemId && !elem.id) {
                    elem.id = lastElemId + 1;
                    lastElemId = elem.id;
                } else if(!lastElemId && !elem.id) {
                    elem.id = index + 1;
                }
            });
            setBasketToCookie(basket);
            for (const elem of basket) {
                const quantity = elem.quantity;
                await createBasketCard(elem, quantity, container);

                const cards = container.querySelectorAll('.basket-order-card');
                const lastCard = cards[cards.length - 1];
                cardDeletions(lastCard);

                const cardCheckBtn = lastCard.querySelector('.__card_to_delete');
                cardCheckBtn.click();

                const slider = lastCard.querySelector('.basket-order-card-slider');
                if(slider) {
                    const pagination = lastCard.querySelector('.basket-order-card__card__slider__pagination');
                    const productSlider = new Swiper(slider, {
                        slidesPerView: 1,
                        pagination: {
                            el: pagination,
                            clickable: true
                        },
                        mousewheel: {
                            forceToAxis: true,
                        },
                    });
                }
            }
        }
    };
}