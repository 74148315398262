export function buttonCounter() {
    const buttonCounterElems = document.querySelectorAll('.button-counter');
    if(buttonCounterElems.length > 0) {
        buttonCounterElems.forEach(btn=>{
            const countBtns = btn.querySelectorAll('.icon-button');
            const span = btn.querySelector('span');
            countBtns.forEach(countBtn=>{
                countBtn.addEventListener('click',()=>{
                    if(countBtn.classList.contains('__button-counter__btn_prev')) {
                        if(Number(span.innerText) > 1) span.innerText = Number(span.innerText) - 1;
                    } else if (countBtn.classList.contains('__button-counter__btn_next')) {
                        span.innerText = Number(span.innerText) + 1;
                    };
                });
            });
        });
    };
}