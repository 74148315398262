export function customRangeInput() {
    const sliderElems = document.querySelectorAll("input[type='range']");
    // const sliderValue = document.querySelector(".value")

    if(sliderElems.length > 0) {
        sliderElems.forEach(elem=>{
            elem.addEventListener("input", (event) => {
                const tempSliderValue = event.target.value; 
                
                // sliderValue.textContent = tempSliderValue;
                
                const progress = (tempSliderValue / elem.max) * 100 - 5;

                elem.style.background = `linear-gradient(to right, #610BD9 ${progress}%, color-mix(in srgb, #FFFFFF 100%, #610BD9 20%) ${progress}%)`;
            })
        })
    }
}