import { findParentWithClass, getData } from "../../utils.js";

export async function createBasketCard(data, quantity, container) {

    const count = Number(data.id);
    let cardNumber = '';
    if(count < 10 && count > 0) {
        cardNumber = `000${count}`;
    } else if(count < 100 && count > 10) {
        cardNumber = `00${count}`;
    } else if (count < 1000 && count > 100) {
        cardNumber = `0${count}`;
    } else if (count < 10000 && count > 1000) {
        cardNumber = `${count}`;
    }

    const orderCard = document.createElement('div');
    orderCard.setAttribute('data-id', data.id);
    orderCard.classList.add('basket-order-card');
    orderCard.innerHTML = `
        <div class="basket-order-card__only-mobile-label">
            <label class="custom-checkbox-label only-mobile" for="${count}-mobile">
                <input type="checkbox" id="${count}-mobile" class="__card_to_delete">
                Подарок №${cardNumber}
            </label>
        </div>
        <div class="basket-order-card__card-block">

        </div>
        <div class="basket-order-card__info-block">
            <label class="custom-checkbox-label" for="${count}">
                <input type="checkbox" id="${count}" class="__card_to_delete">
                Подарок №${cardNumber}
            </label>
            <div class="basket-order-card__info-block__info">
                <span class="basket-order-card__info-block__info__cost"> ₽</span>
                <div class="basket-order-card__info-block__info__btn-block">
                    <div class="button-counter">
                        <button class="icon-button icon-button_outline __button-counter__btn_prev">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 14L21 14" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <span>${quantity}</span>
                        <button class="icon-button icon-button_outline __button-counter__btn_next">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.0007 5.83331V22.1666M5.83398 14H22.1673" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </div>
                    <button class="icon-button desktop-only __card__delete">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.33398 8H26.6673M21.334 8L20.9731 6.91743C20.6234 5.86833 20.4486 5.34379 20.1243 4.95597C19.8379 4.61351 19.4701 4.34843 19.0546 4.18503C18.5842 4 18.0313 4 16.9254 4H15.0759C13.97 4 13.4171 4 12.9467 4.18503C12.5312 4.34843 12.1634 4.61351 11.877 4.95597C11.5527 5.34379 11.3779 5.86833 11.0282 6.91743L10.6673 8M24.0006 8V21.6C24.0006 23.8402 24.0006 24.9603 23.5647 25.816C23.1812 26.5686 22.5693 27.1805 21.8166 27.564C20.961 28 19.8409 28 17.6007 28H14.4007C12.1604 28 11.0403 28 10.1847 27.564C9.43204 27.1805 8.82012 26.5686 8.43662 25.816C8.00065 24.9603 8.00065 23.8402 8.00065 21.6V8M18.6673 13.3333V22.6667M13.334 13.3333V22.6667" stroke="#212121" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <button class="icon-button mobile-only icon-button_primary __card__delete">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.33398 8H26.6673M21.334 8L20.9731 6.91743C20.6234 5.86833 20.4486 5.34379 20.1243 4.95597C19.8379 4.61351 19.4701 4.34843 19.0546 4.18503C18.5842 4 18.0313 4 16.9254 4H15.0759C13.97 4 13.4171 4 12.9467 4.18503C12.5312 4.34843 12.1634 4.61351 11.877 4.95597C11.5527 5.34379 11.3779 5.86833 11.0282 6.91743L10.6673 8M24.0006 8V21.6C24.0006 23.8402 24.0006 24.9603 23.5647 25.816C23.1812 26.5686 22.5693 27.1805 21.8166 27.564C20.961 28 19.8409 28 17.6007 28H14.4007C12.1604 28 11.0403 28 10.1847 27.564C9.43204 27.1805 8.82012 26.5686 8.43662 25.816C8.00065 24.9603 8.00065 23.8402 8.00065 21.6V8M18.6673 13.3333V22.6667M13.334 13.3333V22.6667" stroke="#212121" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    `;

    const countBtn = orderCard.querySelector('.button-counter')
    const countBtns = countBtn.querySelectorAll('.icon-button');
    const span = countBtn.querySelector('span');
    countBtns.forEach(countBtn=>{
        countBtn.addEventListener('click',()=>{
            if(countBtn.classList.contains('__button-counter__btn_prev')) {
                if(Number(span.innerText) > 1) span.innerText = Number(span.innerText) - 1;
            } else if (countBtn.classList.contains('__button-counter__btn_next')) {
                span.innerText = Number(span.innerText) + 1;
            };

            dinamicTotalPrice()
        });
    });

    let inserts = null;

    if(data.inserts && data.inserts.length !== 0) {
        inserts = data.inserts;
    }

    const packingCard = await createPackingCard(data.packing);
    const candySetCard = await createCandySetCard(data.candySet);
    const brandingCard = await createBrandingCard(data.branding);
    const insertCard = await createInsertCard(inserts);

    const cardBlock = orderCard.querySelector('.basket-order-card__card-block');
    cardBlock.appendChild(candySetCard);
    cardBlock.appendChild(packingCard);
    cardBlock.appendChild(insertCard);
    cardBlock.appendChild(brandingCard);

    let price = '';

    const dataPrice = orderCard.querySelectorAll('[data-price]');
    if(dataPrice.length > 0) {
        dataPrice.forEach(elem=>{
            price = Number(price) + Number(elem.getAttribute('data-price'));
        });
    };
    const priceBlock = orderCard.querySelector('.basket-order-card__info-block__info__cost');
    priceBlock.innerHTML = price + ' ₽';

    container.appendChild(orderCard);
}

async function createInsertCard(ids) {
    const insertCard = document.createElement('div');
    if(ids) {
        insertCard.classList.add('basket-order-card__card__slider');
        insertCard.innerHTML = `
            <div class="swiper basket-order-card-slider">
                <div class="swiper-wrapper">

                </div>
                <div class="basket-order-card__card__slider__pagination"></div>
            </div>
        `;
        for(const id of ids) {
            await getData(`/api/inserts/${id}/`)
            .then(data=>{
                const wrapper = insertCard.querySelector('.swiper-wrapper');

                const slide = document.createElement('div');
                slide.classList.add('swiper-slide');

                const photo = data.photo ? data.photo : '/static/assets/images/stub.png';
                slide.innerHTML = `
                <div class="basket-order-card__card" data-price="${data.price}">
                    <div class="basket-order-card__card__img-block">
                        <img src="${photo}" alt="icon">
                    </div>
                    <div class="basket-order-card__card__title-block">
                        <div class="basket-order-card__card__title-block__title">
                            <span>Вложение</span>
                            <p>${data.name}</p>
                        </div>
                        <button class="link link_small link_basic_primary" data-type-modal="attachments-modal" data-id="${data.id}">Изменить</button>
                    </div>
                </div>
                `;

                wrapper.appendChild(slide);
            });
        }
    } else {
        insertCard.classList.add('basket-order-card__card');
        insertCard.innerHTML = `
        <div class="basket-order-card__card__img-block contain">
            <img src="/static/assets/images/bundle-card/noInserts.png" alt="icon">
        </div>
        <div class="basket-order-card__card__title-block">
            <div class="basket-order-card__card__title-block__title">
                <span>Вложение</span>
                <button class="link link_basic link_basic_primary" data-type-modal="attachments-modal">Выбрать</button>
            </div>
        </div>
        `;
    }
    const buttons = insertCard.querySelectorAll('.link');
    buttons.forEach(button=>{
        button.addEventListener('click', (e) => {
            const cardId = findParentWithClass(e.target, 'basket-order-card').getAttribute('data-id')
            openModal(e.target, cardId, buttons);
        });
    });
    return insertCard;
}

async function createBrandingCard(id) {
    const brandingCard = document.createElement('div');
    brandingCard.classList.add('basket-order-card__card');
    if(id) {
        await getData(`/api/branding/${id}/`)
        .then(data=>{
            brandingCard.dataset.price = data.price;
            const photo = data.photo ? data.photo : '/static/assets/images/stub.png';
            brandingCard.innerHTML = `
            <div class="basket-order-card__card__img-block">
                <img src="${photo}" alt="icon">
            </div>
            <div class="basket-order-card__card__title-block">
                <div class="basket-order-card__card__title-block__title">
                    <span>Брендирование</span>
                    <p>${data.name}</p>
                </div>
                <button class="link link_small link_basic_primary" data-type-modal="branding-modal" data-id="${data.id}">Изменить</button>
            </div>
            `;
        });
    } else {
        brandingCard.innerHTML = `
        <div class="basket-order-card__card__img-block contain">
            <img src="/static/assets/images/bundle-card/noBranding.png" alt="icon">
        </div>
        <div class="basket-order-card__card__title-block">
            <div class="basket-order-card__card__title-block__title">
                <span>Брендирование</span>
                <button class="link link_basic link_basic_primary" data-type-modal="branding-modal">Выбрать</button>
            </div>
        </div>
        `;
    }
    const buttons = brandingCard.querySelectorAll('.link');
    buttons.forEach(button=>{
        button.addEventListener('click', (e) => {
            
            const cardId = findParentWithClass(e.target, 'basket-order-card').getAttribute('data-id')
            openModal(e.target, cardId, buttons);
        });
    });
    return brandingCard;
}

async function createCandySetCard(id) {
    const candyCard = document.createElement('div');
    candyCard.classList.add('basket-order-card__card');
    if(id) {
        await getData(`/api/candy-set/${id}/`)
        .then(data=>{
            // data.results.forEach(result=>{
            //     if(result.id == id) {

            const photo = data.photo ? data.photo : '/static/assets/images/stub.png';
            candyCard.setAttribute('data-price', data.price);
            candyCard.innerHTML = `
            <div class="basket-order-card__card__img-block">
                <img src="${photo}" alt="icon">
            </div>
            <div class="basket-order-card__card__title-block">
                <div class="basket-order-card__card__title-block__title">
                    <span>Наполнение</span>
                    <p>${data.name}</p>
                </div>
            </div>
            `;

            //     };
            // });
        });
    } else {
        candyCard.innerHTML = `
        <div class="basket-order-card__card__img-block">
            <img src="/static/assets/images/stub.png" alt="icon">
        </div>
        <div class="basket-order-card__card__title-block">
            <div class="basket-order-card__card__title-block__title">
                <span>Наполнение</span>
                <button class="link link_basic link_basic_primary" data-type-modal="consist-modal">Выбрать</button>
            </div>
        </div>
        `;
    }
    const button = candyCard.querySelector('.link');
    if(button) {
        button.addEventListener('click', (e) => {
            openModal(e.target);
        });
    }
    return candyCard;
}

async function createPackingCard(id) {
    const packingCard = document.createElement('div');
    packingCard.classList.add('basket-order-card__card');
    if(id) {
        await getData(`/api/packing/?id=${id}`)
        .then(data=>{

            const photo = data.results[0].main_photo ? data.results[0].main_photo : '/static/assets/images/stub.png';
            packingCard.setAttribute('data-price', data.results[0].price);
            packingCard.innerHTML = `
            <div class="basket-order-card__card__img-block">
                <img src="${photo}" alt="icon">
            </div>
            <div class="basket-order-card__card__title-block">
                <div class="basket-order-card__card__title-block__title">
                    <span>Упаковка</span>
                    <p>${data.results[0].name}</p>
                </div>
            </div>
            `;

        });
    } else {
        packingCard.innerHTML = `
        <div class="basket-order-card__card__img-block">
            <img src="/static/assets/images/stub.png" alt="icon">
        </div>
        <div class="basket-order-card__card__title-block">
            <div class="basket-order-card__card__title-block__title">
                <span>Упаковка</span>
                <button class="link link_basic link_basic_primary" data-type-modal="package-modal">Выбрать</button>
            </div>
        </div>
        `;
    }
    const button = packingCard.querySelector('.link');
    if(button) {
        button.addEventListener('click', (e) => {
            openModal(e.target);
        });
    }
    return packingCard;
}

export function dinamicTotalPrice() {
    const container = document.querySelector('.basket-section__info-block');
    const totalPriceBlock = document.querySelector('.basket-section__price-bill__title-block__cost');

    let totalPrice = 0;
    const cards = container.querySelectorAll('.basket-order-card');
    cards.forEach(card=>{
        const buttonChecked = card.querySelectorAll('.__card_to_delete');
        let checked = false;
        buttonChecked.forEach(btn=>{
            if(btn.checked) {
                checked = true;
            };
        });

        const counters = card.querySelectorAll('.button-counter');

        counters.forEach(counter=>{
            const price = parseInt(card.querySelector('.basket-order-card__info-block__info__cost').innerText);
            const multiplier = parseInt(counter.querySelector('span').innerText);

            if(checked) {
                const multipliedPrice = price * multiplier;
                totalPrice = totalPrice + multipliedPrice;
            };
        });
    });

    totalPriceBlock.innerText = totalPrice + ' ₽';
}

function openModal(target, cardId, buttons) {
    const modalValue = target.dataset.typeModal;
    const modalElement = document.querySelector(`[data-type="${modalValue}"]`);

    modalElement.dataset.modalState = "open";

    document.documentElement.style.overflow = "hidden";
    if(cardId) {
        modalElement.dataset.openId = cardId;
    };
    if(buttons.length > 0) {
        setActiveToModalBtns(modalElement, buttons);
    };
}

function setActiveToModalBtns(modal, buttons) {
    const modBtns = modal.querySelectorAll('.__add_mod_btn');

    let selectedIds = [];
    buttons.forEach(selected=>{
        if(selected.hasAttribute('data-id')) {
            const id = selected.getAttribute('data-id');
            selectedIds.push(id);
        }
    });
    modBtns.forEach(btn=>{
        if(selectedIds.includes(btn.dataset.id)) {
            btn.checked = true;
            btn.parentNode.querySelector('span').innerHTML = 'Удалить';
        } else {
            btn.checked = false;
            btn.parentNode.querySelector('span').innerHTML = 'Выбрать';
        }
    });
}