import { addProductInBasket, getBasketFromCookie } from "./cookies.js";

export function addInBasket() {
    let basket = getBasketFromCookie();
    console.log(basket);

    const basketBtns = document.querySelectorAll('.__add_in_basket__btn');
    document.addEventListener('DOMContentLoaded',()=>{
        updBasketValue() 
    });
    if(basketBtns.length > 0) {
        basketBtns.forEach(btn=>{
            const btnId = btn.getAttribute('data-id');
            btn.addEventListener('click',()=>{
                const productCardId = document.querySelector('.__product_card__id');
                const orderNav = document.querySelector('.order-navigation');
                if(productCardId) {
                    const buttonCounter = btn.parentNode.querySelector('.button-counter');
                    const candySetBtn = document.querySelector('.__candy_set__page__btn:checked');

                    const titles = document.querySelectorAll(`.__add_mod_title[data-mod]`);
                    let inserts = [];
                    let branding = '';
                    titles.forEach(title=>{
                        const value = title.getAttribute('data-value')
                        if(title.getAttribute('data-mod') == 'inserts' && value) inserts.push(parseInt(value));
                        if(title.getAttribute('data-mod') == 'branding' && value) branding = value;
                    });
                    
                    const quantity = buttonCounter.querySelector('span').innerText;
                    if(quantity == '0') quantity = 1;
                    
                    console.log(candySetBtn);

                    addProductInBasket(
                        Number(btnId), 
                        Number(quantity), 
                        Number(candySetBtn.getAttribute('data-id')),
                        inserts,
                        Number(branding),
                    );
                } else if(orderNav) {
                    const candySet = document.querySelector('.gift-compositions-section__menu__item.show').dataset.id;
                    const buttonCounter = orderNav.querySelector('.button-counter span').innerText;
                    const packing = orderNav.querySelector('.__packing-val').dataset.value;
                    const insertsVal = orderNav.querySelector('.__inserts-val').dataset.value;
                    const brand = orderNav.querySelector('.__brand-val').dataset.value;

                    let inserts = [];

                    if(!!insertsVal) {
                        inserts = insertsVal.split(',').map(val => parseInt(val.trim()));
                    }

                    addProductInBasket(
                        parseInt(packing),
                        parseInt(buttonCounter),
                        parseInt(candySet),
                        inserts,
                        parseInt(brand),
                    );
                    window.location.search = '';
                } else {
                    const card = btn.closest('.product-card');
                    const candySetBtn = card.querySelector('.__candy_set__btn:checked');
                    if(candySetBtn) {
                        addProductInBasket(Number(btnId), 1, Number(candySetBtn.getAttribute('data-id')));
                    } else {
                        addProductInBasket(Number(btnId), 1);
                    }
                }


                let basket = getBasketFromCookie();

                console.log(basket);

                updBasketValue();
            });
        });
    };
};

export function updBasketValue() {
    const basket = getBasketFromCookie();
    let fullQuantity = 0;
    basket.forEach(elem=>{
        const quantity = elem.quantity;
        fullQuantity = fullQuantity + quantity;
    });

    const basketValueElems = document.querySelectorAll('.__basket_value_counter');
    if(basketValueElems.length > 0) {
        basketValueElems.forEach(elem=>{
            if(fullQuantity !== 0) {
                elem.classList.add('show');
                elem.classList.add('active');
            } else {
                elem.classList.remove('show');
            };
            
            setTimeout(() => {
                elem.innerHTML = fullQuantity;
                elem.classList.remove('active');
            }, 150);
        })
    };
}