export function fileObserver() {
    document.addEventListener("DOMContentLoaded", function() {
        const lazyElements = document.querySelectorAll("img[data-src], video[data-src]");

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const element = entry.target;

                    // Если элемент - картинка
                    if (element.tagName === "IMG") {
                        element.src = element.dataset.src;
                        element.removeAttribute("data-src");
                    }

                    // Если элемент - видео
                    if (element.tagName === "VIDEO") {
                        element.src = element.dataset.src;
                        const sources = element.querySelectorAll("source[data-src]");
                        sources.forEach(source => {
                            source.src = source.dataset.src;
                            source.removeAttribute("data-src");
                        });
                        element.load(); // Загружаем видео с обновленными источниками

                        // Устанавливаем autoplay, loop и muted
                        element.autoplay = true;
                        element.loop = true;
                        element.muted = true; // Отключаем звук для обеспечения автоплея

                        // Пытаемся воспроизвести видео
                        const playPromise = element.play();
                        if (playPromise !== undefined) {
                            playPromise.then(() => {
                                // Автоплей успешно начался
                            }).catch(error => {
                                // Автоплей не удался (например, из-за политики браузера)
                                console.warn("Автоплей видео не удался:", error);
                            });
                        }
                    }

                    observer.unobserve(element); // Перестаем наблюдать за элементом после его загрузки
                }
            });
        }, {
            rootMargin: "0px 0px 50px 0px" // Подгрузка за 50px до видимости
        });

        lazyElements.forEach(element => {
            observer.observe(element);
        });
    });
}