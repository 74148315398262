import { itemsFilter } from './itemsFilter.js';
import { orderNav } from './orderNav.js';

function init() {
    itemsFilter();
    orderNav();
}

export {
    init   
}