import * as main from './main/index.js';
import * as news from './news/index.js';
import * as basket from './basket/index.js';
import * as giftCompositions from './giftCompositions/index.js';
import * as productCard from './productCard/index.js';
import * as packages from './packages/index.js';

main.init();
news.init();
basket.init();
giftCompositions.init();
productCard.init();
packages.init();
