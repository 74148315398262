import { getCurrentQuery, setNewQuery } from "../../utils.js";

export function itemsFilter() {
    const giftItems = document.querySelectorAll('.gift-compositions-section__menu__item');
    const currentUrl = window.location.pathname;
    if(giftItems.length > 0 && currentUrl.includes('gift-compositions')) {
        giftItems.forEach(item=>{
            item.addEventListener('click',()=>{
                const id = item.getAttribute('data-id');
                setNewQuery('set_id', Number(id));

                giftItems.forEach(elem=>{
                    const findBodyElem = elem.querySelector('.gift-compositions-section__menu__item__body');

                    if(elem !== item) {
                        elem.classList.remove('show');
                        findBodyElem.style.maxHeight = null;
                    } else {
                        elem.classList.add('show');
                        findBodyElem.style.maxHeight = findBodyElem.scrollHeight * 2 + "px";
                    };
                });
            });
        });

        const activeQueries = getCurrentQuery();
        if(activeQueries.set_id) {
            const activeItem = document.querySelector(`.gift-compositions-section__menu__item[data-id="${activeQueries.set_id[0]}"]`);

            setNewTitle(activeItem);
            
            setFullPrice(activeItem);

            const findBodyElem = activeItem.querySelector('.gift-compositions-section__menu__item__body');
            activeItem.classList.add('show');
            findBodyElem.style.maxHeight = findBodyElem.scrollHeight * 2 + "px";
        } else {
            const activeItem = document.querySelector(".gift-compositions-section__menu__item");
            activeItem.click()
        }
    };
}
function setNewTitle(activeItem) {
    const activeItemtitle = activeItem.querySelector('.__item__title');
    const choosenSet = document.querySelector('.__choosen_set');
    choosenSet.innerHTML = activeItemtitle.innerHTML;
}
function setFullPrice(activeItem) {
    const activeItemPrice = activeItem.querySelector('.__item__price');
    const giftFullPrice = document.querySelector('.__gift__full_price');
    giftFullPrice.innerHTML = parseInt(activeItemPrice.innerText) + ' ₽';
}