import { calculateTotalPrice } from "../pages/main/calcBundleCard.js";
import { getCurrentQuery } from "../utils.js";

export function packageModal() {
    const modalBody = document.querySelector('.__package_modal__body');
    if(!modalBody) return;
    const modal = document.querySelector('[data-type="package-modal"]');
    const modalInset = modal.querySelector('.modal__inset');
    
    modalInset.addEventListener('click',()=>{
        window.location.reload();
    });

    const currentQuiries = getCurrentQuery();
    const modBtns = modalBody.querySelectorAll('.__add_mod_btn');
    const confirmBtns = document.querySelectorAll('.__confirm_mod_btn');
    const packageCard = document.querySelector('.__packing_card');
    modBtns.forEach(btn=>{
        if(currentQuiries['packing'] && btn.dataset.id == currentQuiries['packing'][0]) {
            btn.checked = true;
            btn.parentNode.querySelector('span').innerHTML = 'Удалить';
            setTitle(btn);
            calculateTotalPrice();
            packageCard.classList.remove('error');
        };
        btn.addEventListener('click',()=>{
            setNewUrl(btn.dataset.id);
        });
    });
    if(confirmBtns.length === 0) return;
    confirmBtns.forEach(btn=>{
        btn.addEventListener('click',()=>{
            window.location.reload();
        });
    });
    const cancelBtns = document.querySelectorAll('.__cancel_mod_btn');
    if(cancelBtns.length === 0) return;
    cancelBtns.forEach(btn=>{
        btn.addEventListener('click',()=>{
            window.location.search = '';
        });
    });
}

function setNewUrl(id) {
    const state = { additionalInformation: 'Replace State' };
    const title = 'Replace State Example';
    let url = `/?packing=${id}`;

    const currentSet = getCurrentQuery()['set'];
    if(currentSet) url = `/?packing=${id}&set=${currentSet[0]}`;

    history.replaceState(state, title, url);
}

function setTitle(item) {
    const modification = item.getAttribute('data-mod');
    const modTitle = document.querySelector(`.__add_mod_title[data-mod="${modification}"]`);
    const modImage = document.querySelector(`.__add_mod_image[data-image="${modification}"]`);
    if(modTitle.getAttribute('name') == '') modTitle.innerHTML = item.dataset.name;
    modTitle.setAttribute('data-value', item.getAttribute('data-id'));
    modTitle.setAttribute('data-price', item.getAttribute('data-price'));
    if(!!item.dataset.image) modImage.src = item.dataset.image;
}