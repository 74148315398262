import { getRem } from "./slider.js";

export function orderNav() {
    const orderNavElem = document.querySelector('.order-navigation');
    if(orderNavElem) {
        window.addEventListener('scroll',()=>{
            const seoElem = document.querySelector('.seo-text-section');
            const rectSeo = seoElem.getBoundingClientRect();
            if(rectSeo.top < 0 || rectSeo.top <= window.innerHeight) {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const top = rectSeo.top + scrollTop;
                orderNavElem.style.position = 'absolute';
                if(window.innerWidth > 768) {
                    orderNavElem.style.top = `${top - getRem() * 2.5}px`;
                } else {
                    orderNavElem.style.top = `${top - getRem() * 24 + rectSeo.height}px`;
                }
            } else {
                orderNavElem.style.position = null;
                orderNavElem.style.top = null;
            }
        });
    };
}