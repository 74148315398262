import { getBasketFromCookie, setBasketToCookie } from "../../cookies.js";
import { dinamicTotalPrice } from "./createBasketCard.js";

export function deleteBasketCards() {
    const container = document.querySelector('.basket-section__info-block');
    if(container) {
        const chooseAllToDelete = allToDeleteBtn()
        chooseAllToDelete.addEventListener('click',()=>{
            const selectToDelete = selectToDeleteBtns(container);
            if(chooseAllToDelete.checked) {
                selectToDelete.forEach(select=>{
                    select.checked = true;
                });
            } else {
                selectToDelete.forEach(select=>{
                    select.checked = false;
                });
            }
        });

        const deleteSelectedBtn = document.querySelector('.__delete_selected__btn');
        deleteSelectedBtn.addEventListener('click',()=>{
            const cards = container.querySelectorAll('.basket-order-card');
            const basket = getBasketFromCookie();
            cards.forEach(card=>{
                const cardId = parseInt(card.dataset.id);
                const deleteCardBtn = selectToDeleteBtns(card);
                let cardChecked = false;
                deleteCardBtn.forEach(btn=>{
                    if(btn.checked) {
                        cardChecked = true;
                    };
                });
                basket.forEach(elem=>{
                    if(elem.id == cardId) {
                        const elemIndex = basket.indexOf(elem);
                        basket.splice(elemIndex, 1);
                    }
                })
                setBasketToCookie(basket);

                setTimeout(() => {
                    window.location.reload();
                }, 100);
            });
        });
    };
}

export function cardDeletions(card) {
    const cardId = parseInt(card.dataset.id);
    const deleteCardBtns = card.querySelectorAll('.__card__delete');
    deleteCardBtns.forEach(btn=>{
        btn.addEventListener('click',()=>{
            const basket = getBasketFromCookie();
            basket.forEach(elem=>{
                if(elem.id == cardId) {
                    const elemIndex = basket.indexOf(elem);
                    basket.splice(elemIndex, 1);
                }
            })
            setBasketToCookie(basket);

            window.location.reload();
        });
    });

    const selectToDelete = selectToDeleteBtns(card);
    selectToDelete.forEach(select=>{
        select.addEventListener('click',()=>{
            if(select.checked) {
                selectToDelete.forEach(elem=>{
                    elem.checked = true;
                });
            } else {
                selectToDelete.forEach(elem=>{
                    elem.checked = false;
                });
            };
            checkForAllChoosen();
            setTimeout(() => {
                dinamicTotalPrice();
            }, 50);
        });
    });
}

function checkForAllChoosen() {
    let somethingUnchecked = false;

    const selectToDelete = selectToDeleteBtns();
    const chooseAllToDelete = allToDeleteBtn();
    selectToDelete.forEach(btn=>{
        if (!btn.checked) somethingUnchecked = true;
    });
    chooseAllToDelete.checked = !somethingUnchecked;
}

function allToDeleteBtn() {
    const chooseAllToDelete = document.getElementById('choose-all');
    return chooseAllToDelete;
}
function selectToDeleteBtns(container) {
    const selectToDelete = container ? container.querySelectorAll('.__card_to_delete') : document.querySelectorAll('.__card_to_delete');
    return selectToDelete;
}