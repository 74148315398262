export function santaAnimation() {
    const santa = document.querySelector('.__santa__animation');
    if(santa) {
        document.addEventListener('scroll',()=>{
            const rect = santa.getBoundingClientRect();
            if(rect.top < 0 || rect.top <= window.innerHeight) {
                santa.style.left = `${rect.top / 2}px`;
            }
        });
    };
}