export function filtersMore() {
    const filterBlock = document.querySelectorAll('.__filter-items');
    const button = document.querySelector('.__show-button');
    if(!!filterBlock && button) {
        button.addEventListener('click',()=>{
            button.classList.toggle('show');
            filterBlock.forEach(filter=>{
                filter.classList.toggle('show');
            });
        });
    }
}