export function candyFabricAutoSlider() {
    const slidersContainers = document.querySelectorAll('.candy-fabric-section__container');
    if(slidersContainers.length > 0) {
        slidersContainers.forEach(container=>{
            const slider = container.querySelector('.candy-fabric-section__slider');
            duplicateSlider(slider, container);

            const cards = container.querySelectorAll('.candy-fabric-section__slider__card');
            cards.forEach(card=>{
                const sub = card.querySelector('.candy-fabric-section__slider__card__sub');
                const text = sub.querySelector('.candy-fabric-section__slider__card__sub__text');
                card.addEventListener('mouseover',()=>{
                    sub.style.height = `calc(100% + ${text.scrollHeight}px)`;
                });
                card.addEventListener('mouseout',()=>{
                    sub.style.height = null;
                })
            });
        });
    };
}

function duplicateSlider(slider, container) {
    const newSlider = document.createElement('div');
    newSlider.classList.add('candy-fabric-section__slider');
    newSlider.innerHTML = slider.innerHTML;
    container.appendChild(newSlider);
}
