export function slider() {
    const boxCardSliders = document.querySelectorAll('.box-card-slider');

    boxCardSliders.forEach((mainSlider, index) => {
        const pagination = document.querySelectorAll('.box-card__image-slider__pagination')[index];
        const slider = new Swiper(mainSlider, {
            slidesPerView: 1,
            pagination: {
                el: pagination,
                clickable: true
            },
            mousewheel: {
                forceToAxis: true,
            },
        });
    });
    
    const productCardsSlider = document.querySelectorAll('.__product_images_slider');
    productCardsSlider.forEach((productSlider, index)=>{
        const pagination = document.querySelectorAll('.product-card__image-slider__pagination')[index];
        const slider = new Swiper(productSlider, {
            slidesPerView: 1,
            pagination: {
                el: pagination,
                clickable: true
            },
            mousewheel: {
                forceToAxis: true,
            },
        });
    });

    const mainHero = new Swiper('.main-hero-slider', {
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: '.main-hero-section__navigation__btn_next',
            prevEl: '.main-hero-section__navigation__btn_prev',
        },
        pagination: {
            el: '.main-hero-section__pagination',
            clickable: true
        },
        mousewheel: {
            forceToAxis: true,
        },
    });

    const productCardSub = new Swiper('.product-card-slider-sub', {
        slidesPerView: 4.45,      
        spaceBetween: getRem() * .75,
        breakpoints: {
            768: {
                slidesPerView: 5.4, 
                direction: 'vertical',
            }
        },
        mousewheel: {
            forceToAxis: true,
        },
    });
    const productCard = new Swiper('.product-card-slider', {
        slidesPerView: 1,
        breakpoints: {
            768: {
                direction: 'vertical',
            }
        },
        thumbs: {
            swiper: productCardSub
        },     
        mousewheel: {
            forceToAxis: true,
            releaseOnEdges: true,
        },
    });

    // const attachmets = new Swiper('.attachments-section-slider', {
    //     slidesPerView: 1.39,
    //     spaceBetween: getRem() * 2,
    //     slidesOffsetBefore: getRem() * 2,
    //     slidesOffsetAfter: getRem() * 2.,
    //     breakpoints: {
    //         768: {
    //             slidesPerView: 3.75,
    //             spaceBetween: getRem() * 2.375,
    //             slidesOffsetBefore: getRem() * 12.5,
    //             slidesOffsetAfter: getRem() * 12.5,
    //         }
    //     },
    //     mousewheel: {
    //         forceToAxis: true,
    //     },
    // });
    // const attachmetsSecond = new Swiper('.attachments-section-slider-second', {
    //     slidesPerView: 1.39,
    //     spaceBetween: getRem() * 2,
    //     slidesOffsetBefore: getRem() * 2,
    //     slidesOffsetAfter: getRem() * 2,
    //     breakpoints: {
    //         768: {
    //             slidesPerView: 3.75,
    //             spaceBetween: getRem() * 2.375,
    //             slidesOffsetBefore: getRem() * 12.5,
    //             slidesOffsetAfter: getRem() * 12.5,
    //         }
    //     },
    //     mousewheel: {
    //         forceToAxis: true,
    //     },
    // });

    // attachmets.controller.control = attachmetsSecond;
    // attachmetsSecond.controller.control = attachmets;

    // const branding = new Swiper('.branding-section-slider', {
    //     slidesPerView: 1.39,
    //     spaceBetween: getRem() * 2,
    //     slidesOffsetBefore: getRem() * 2,
    //     slidesOffsetAfter: getRem() * 2,
    //     breakpoints: {
    //         768: {
    //             slidesPerView: 3.75,
    //             spaceBetween: getRem() * 2.375,
    //             slidesOffsetBefore: getRem() * 12.5,
    //             slidesOffsetAfter: getRem() * 12.5,
    //         }
    //     },
    //     mousewheel: {
    //         forceToAxis: true,
    //     },
    // });
    // const brandingSecond = new Swiper('.branding-section-slider-second', {
    //     slidesPerView: 1.39,
    //     spaceBetween: getRem() * 2,
    //     slidesOffsetBefore: getRem() * 2,
    //     slidesOffsetAfter: getRem() * 2,
    //     breakpoints: {
    //         768: {
    //             slidesPerView: 3.75,
    //             spaceBetween: getRem() * 2.375,
    //             slidesOffsetBefore: getRem() * 12.5,
    //             slidesOffsetAfter: getRem() * 12.5,
    //         }
    //     },
    //     mousewheel: {
    //         forceToAxis: true,
    //     },
    // });

    // branding.controller.control = brandingSecond;
    // brandingSecond.controller.control = branding;

    const alsoLike = new Swiper('.also-like-slider', {
        slidesPerView: 3,
        spaceBetween: getRem() * 2.375,
        slidesOffsetBefore: getRem() * 2,
        slidesOffsetAfter: getRem() * 2,
        breakpoints: {
            768: {
                slidesPerView: 4.839,
                spaceBetween: getRem() * 2.5,
                slidesOffsetBefore: getRem() * 12.5,
                slidesOffsetAfter: getRem() * 12.5,
            }
        },
        mousewheel: {
            forceToAxis: true,
        },
    });

    const modalConsists = document.querySelectorAll('.modal-consist-slider');
    modalConsists.forEach(slider=>{
        const modalConsist = new Swiper (slider, {
            slidesPerView: 1.39,
            spaceBetween: getRem() * 2,
            breakpoints: {
                768: {
                    slidesPerView: 5,
                    spaceBetween: getRem() * 1.25,
                }
            },
            mousewheel: {
                forceToAxis: true,
            },
        });
    });

    const modalPackages = document.querySelectorAll('.package-modal-slider');
    modalPackages.forEach(slider=>{
        const modalPackage = new Swiper (slider, {
            slidesPerView: 2.26,
            spaceBetween: getRem() * 2,
            slidesOffsetAfter: getRem() * 2,
            slidesOffsetBefore: getRem() * 2,
            breakpoints: {
                768: {
                    slidesPerView: 3.834,
                    spaceBetween: getRem() * 1.25,
                    slidesOffsetAfter: getRem() * 4,
                    slidesOffsetBefore: getRem() * 4,
                }
            },
        });
    });

    const modalAttachments = document.querySelectorAll('.attachments-modal-slider');
    modalAttachments.forEach(slider=>{
        const modalAttachment = new Swiper (slider, {
            slidesPerView: 1.3815,
            spaceBetween: getRem() * 2,
            slidesOffsetAfter: getRem() * 2,
            slidesOffsetBefore: getRem() * 2,
            breakpoints: {
                768: {
                    slidesPerView: 3.334,
                    spaceBetween: getRem() * 1.25,
                    slidesOffsetAfter: getRem() * 4,
                    slidesOffsetBefore: getRem() * 4,
                }
            }
        });
    });
    const modalBrandings = document.querySelectorAll('.branding-modal-slider');
    modalBrandings.forEach(slider=>{
        const modalBranding = new Swiper (slider, {
            slidesPerView: 1.3815,
            spaceBetween: getRem() * 2,
            slidesOffsetAfter: getRem() * 2,
            slidesOffsetBefore: getRem() * 2,
            breakpoints: {
                768: {
                    slidesPerView: 3.334,
                    spaceBetween: getRem() * 1.25,
                    slidesOffsetAfter: getRem() * 4,
                    slidesOffsetBefore: getRem() * 4,
                }
            }
        });
    });

    // const candyFabric = new Swiper('.candy-fabric-slider', {
    //     slidesPerView: 1.85,
    //     spaceBetween: getRem() * 3,
    //     slidesOffsetBefore: getRem() * 2,
    //     slidesOffsetAfter: getRem() * 2,
    //     breakpoints: {
    //         768: {
    //             slidesPerView: 5,
    //             spaceBetween: getRem() * 6,
    //             slidesOffsetBefore: getRem() * 2,
    //             slidesOffsetAfter: getRem() * 2,
    //         }
    //     },
    //     mousewheel: {
    //         forceToAxis: true,
    //     },
    // });
    // const candyFabricSecond = new Swiper('.candy-fabric-slider-second', {
    //     slidesPerView: 1.85,
    //     spaceBetween: getRem() * 3,
    //     breakpoints: {
    //         768: {
    //             slidesPerView: 5,
    //             spaceBetween: getRem() * 6,
    //         }
    //     },
    //     mousewheel: {
    //         forceToAxis: true,
    //     },
    // });

    // candyFabric.controller.control = candyFabricSecond;
    // candyFabricSecond.controller.control = candyFabric;
}

export function getRem() {
    var element = document.querySelector("html");
    var fontSize = window.getComputedStyle(element).fontSize;
    var numericFontSize = parseInt(fontSize, 8);
    return fontSize.slice(0, -2);
}


// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { slider } from "./путь/к/файлу/slider.js";

// Активация: slider();