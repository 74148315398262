import { showCards } from './showCards.js';
import { deleteBasketCards } from './deleteBasketCards.js';
import { basketModals } from './basketModals.js';

function init() {
    showCards();
    deleteBasketCards();
    basketModals();
}

export {
    init
}