export async function ApplyFilterOnProductCard() {
    const currentUrl = window.location.pathname;
    if (!currentUrl.includes('packages')) return;

    const query = getGramsQueryParams();
    if (query.length === 0) return;

    setActiveButtons(query);

    const firstQuery = query[0];
    const productCards = document.querySelectorAll('.product-card');

    const promises = Array.from(productCards).map(async (productCard) => {
        const productCardId = productCard.getAttribute('data-productCardId');
        if (!productCardId) return;

        const activeLabels = productCard.querySelectorAll('.product-card__info-block__buttons label.button.button_outline_active');
        if (activeLabels.length !== 2) return;

        let type = '';
        let weight = '';
        activeLabels.forEach(label => {
            const labelText = label.textContent.trim();
            if (label.querySelector('input[type="radio"]')) {
                type = labelText;
            } else {
                weight = labelText.replace(' г.', '');
            }
        });

        if (type !== firstQuery[1] || weight !== firstQuery[0]) return;

        const typeLabel = Array.from(activeLabels).find(label => label.querySelector('input[type="radio"]'));
        const forAttr = typeLabel.getAttribute('for');
        const setId = extractSetIdFromForAttribute(forAttr);
        if (!setId) return;

        const apiUrl = `/api/packing-prices/${productCardId}/?set=${setId}`;
        try {
            const response = await fetch(apiUrl);
            if (!response.ok) throw new Error(`Ошибка HTTP! статус: ${response.status}`);
            const prices = await response.json();
            updatePriceBlock(productCard, prices.prices);
        } catch (error) {
            console.error('Ошибка при получении цен из API:', error);
        }
    });

    await Promise.all(promises);
}

function getGramsQueryParams(url = window.location.search) {
    const urlObj = new URL(url, window.location.origin);
    const gramsParams = urlObj.searchParams.getAll('grams');
    const parsedGrams = gramsParams.map(parseGramParam);
    const uniqueGrams = removeDuplicates(parsedGrams);
    return uniqueGrams;
}

function parseGramParam(param) {
    const [type, value] = param.split('_');
    return [value, type];
}

function removeDuplicates(grams) {
    const seen = new Set();
    const unique = [];
    for (const gram of grams) {
        const key = gram.join('_');
        if (!seen.has(key)) {
            seen.add(key);
            unique.push(gram);
        }
    }
    return unique;
}

function setActiveButtons(query) {
    const productCards = document.querySelectorAll('.product-card');
    productCards.forEach(productCard => {
        const buttonBlock = productCard.querySelector('.product-card__info-block__buttons');
        if (!buttonBlock) return;

        const typeBlock = buttonBlock.querySelector('.product-card__info-block__buttons__type-block');
        const weightsBlock = buttonBlock.querySelector('.product-card__info-block__buttons__weights');
        if (!typeBlock || !weightsBlock) return;

        const typeLabels = typeBlock.querySelectorAll('label.button');
        const weightLabels = weightsBlock.querySelectorAll('label.button');

        clearActiveClasses(typeLabels, true);
        clearActiveClasses(weightLabels, false);

        const forMap = new Map();
        typeLabels.forEach(label => {
            const forAttr = label.getAttribute('for');
            const typeText = label.textContent.trim();
            if (!forMap.has(forAttr)) {
                forMap.set(forAttr, { type: typeText, weight: '', typeLabel: label, weightLabel: null });
            } else {
                forMap.get(forAttr).type = typeText;
                forMap.get(forAttr).typeLabel = label;
            }
        });

        weightLabels.forEach(label => {
            const forAttr = label.getAttribute('for');
            const weightText = label.textContent.trim();
            if (!forMap.has(forAttr)) {
                forMap.set(forAttr, { type: '', weight: weightText, typeLabel: null, weightLabel: label });
            } else {
                forMap.get(forAttr).weight = weightText;
                forMap.get(forAttr).weightLabel = label;
            }
        });

        query.forEach(([weight, type]) => {
            const formattedWeight = `${weight} г.`;
            for (const [forId, entry] of forMap.entries()) {
                if (entry.type === type && entry.weight === formattedWeight) {
                    if (entry.typeLabel) {
                        entry.typeLabel.classList.add('button_outline_active');
                        const typeInput = entry.typeLabel.querySelector('input[type="radio"]');
                        if (typeInput) typeInput.checked = true;
                    }

                    if (entry.weightLabel) {
                        entry.weightLabel.classList.add('button_outline_active');
                        const weightInput = entry.weightLabel.querySelector('input[type="radio"], input[type="checkbox"]');
                        if (weightInput) weightInput.checked = true;
                    }
                }
            }
        });
    });
}

function clearActiveClasses(labels, isRadio) {
    labels.forEach(label => {
        label.classList.remove('button_outline_active');
        const input = label.querySelector('input[type="radio"], input[type="checkbox"]');
        if (input) input.checked = false;
    });
}

function extractSetIdFromForAttribute(forAttr) {
    const match = forAttr.match(/product-card__candyset-(\d+)-\d+/);
    return match ? match[1] : null;
}

function updatePriceBlock(productCard, prices) {
    const accordionContent = productCard.querySelector('.product-card__accordion__content');
    if (!accordionContent) return;

    const headerTitleBlock = productCard.querySelector('.product-card__accordion__header__title-block');
    if (!headerTitleBlock) return;

    if (prices.length > 0) {
        const headerPriceSpan = headerTitleBlock.querySelector('span');
        if (headerPriceSpan) headerPriceSpan.textContent = `${prices[0].price} ₽/шт.`;
    }

    accordionContent.innerHTML = '';
    for (let i = 1; i < prices.length; i++) {
        const { price, quantity } = prices[i];
        const lineDiv = document.createElement('div');
        lineDiv.classList.add('product-card__accordion__content__line');

        const priceSpan = document.createElement('span');
        priceSpan.textContent = `${price} ₽/шт.`;

        const quantitySpan = document.createElement('span');
        quantitySpan.textContent = ` от ${quantity} шт.`;

        lineDiv.appendChild(priceSpan);
        lineDiv.appendChild(quantitySpan);
        accordionContent.appendChild(lineDiv);
    }
}