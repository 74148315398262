import { getCurrentQuery, getData } from "../../utils.js";

export function getCardPrice() {
    const productCardId = document.querySelector('.__product_card__id');
    if (!productCardId) return;
    const addModBtns = document.querySelectorAll('.__add_mod_btn');
    addModBtns.forEach(btn=>{
        btn.addEventListener('click',()=>{
            const addModBtnsChecked = document.querySelectorAll('.__add_mod_btn:checked');
            const queries = getCurrentQuery();
            let set = queries['set'][0];
            let branding = '';
            let inserts = '';
            addModBtnsChecked.forEach(checked=>{
                if(checked.dataset.mod == 'branding') branding = `&branding=${checked.dataset.id}`;
                if(checked.dataset.mod == 'inserts') inserts = inserts + `&insert=${checked.dataset.id}`;
            })
            getData(`/api/packing-prices/${productCardId.value}/?set=${set}${branding}${inserts}`)
            .then(res=>{
                const cardPrices = document.querySelectorAll('.__product_card__price');
                cardPrices.forEach(price=>{
                    res.prices.forEach(apiPrice=>{
                        if(parseInt(price.dataset.quantity) == apiPrice.quantity && apiPrice.quantity == 1) {
                            price.innerHTML = `${apiPrice.price} ₽`;
                        } else if(parseInt(price.dataset.quantity) == apiPrice.quantity && apiPrice.quantity !== 1) {
                            price.innerHTML = `${apiPrice.price}  ₽/шт`;
                        };
                    });
                });
            });
        });
    });
}