export function popUp() {
    const popUpElems = document.querySelectorAll('.__pop_up');
    popUpElems.forEach(elem=>{
        let delay = elem.getAttribute('data-delay');
        if(delay) delay = Number(delay) * 1000;
        document.addEventListener('DOMContentLoaded',()=>{
            if(delay) {
                setTimeout(() => {
                    elem.style.scale = 1;
                    elem.style.opacity = 1; 
                }, delay);
            } else {
                elem.style.scale = 1;
                elem.style.opacity = 1;
            };
        });
    });
}