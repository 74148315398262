import { getCurrentQuery, getData } from "../../utils.js";

export function candySets() {
    const candySetBtns = document.querySelectorAll('.__candy_set__page__btn');
    const productCardId = document.querySelector('.__product_card__id');

    if(candySetBtns.length > 0 && productCardId) {
        const currentQuieries = getCurrentQuery();

        candySetBtns.forEach((btn, index)=>{
            const candySetId = btn.getAttribute('data-id');
            if(currentQuieries['set'] == candySetId){
                btn.checked = true;
                setActiveState();
            }
            btn.addEventListener('click',()=>{
                const url = new URLSearchParams();
                url.append('set', btn.dataset.id);
                window.location.search = url.toString();
            });

            document.addEventListener('DOMContentLoaded',()=>{
                if(!currentQuieries['set'] && index == 0) btn.click();
            });
        });
    };
}

function setActiveState() {
    const candySetBtns = document.querySelectorAll('.__candy_set__page__btn');
    candySetBtns.forEach(btn=>{
        const labels = document.querySelectorAll(`label[for="${btn.id}"]`);
        if(btn.checked){
            labels.forEach(label=>{
                label.classList.add('button_active');
            });
        } else {
            labels.forEach(label=>{
                label.classList.remove('button_active');
            });
        };
    });
}