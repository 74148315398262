export function newsFilters() {
    const filterElems = document.querySelectorAll('.__filter__elem_news');
    if(filterElems.length > 0) {
        const selectTitle = document.querySelector('.__select__title')
        filterElems.forEach(elem=>{
            const filterValue = elem.getAttribute('data-filter');
            const searchFilters = window.location.search.substring(1);
            let activeFilters = searchFilters.split('&');
            activeFilters.forEach(filter=>{
                let filterObj = {};
                let filterArr = filter.split('=');
                filterObj[`${filterArr[0]}`] = filterArr[1];
                if(filterObj.sort == filterValue) {
                    const label = document.querySelector(`label[for="${filterArr[1]}"]`);
                    selectTitle.innerHTML = `
                        <span>Фильтрация: </span>${label.innerText}
                    `
                    elem.checked;
                };
            });

            elem.addEventListener('click',()=>{
                const url = new URLSearchParams();
                url.append('sort', filterValue);
                window.location.search = url.toString();
            });
        });
    };
}