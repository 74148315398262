import { getBasketFromCookie, getGiftItemsCookie, setGiftItemsCookie } from "../cookies.js";
import { getCurrentQuery } from "../utils.js";

export function formalizationModal() {
    const modalBtn = document.querySelector('[data-type-modal=formalization-modal]');
    if(modalBtn) {
        const modal = document.querySelector('.modal[data-type="formalization-modal"]');
        
        modalBtn.addEventListener('click',()=>{
            if(modal.dataset.modalForm == 'gift-compositions-form') {
                const addModBtns = document.querySelectorAll('.__add_mod_btn');
                const orderNav = document.querySelector('.order-navigation');
                const quantity = orderNav.querySelector('.button-counter').querySelector('span').innerText;

                const totalGift = {};

                totalGift.candySet = Number(getCurrentQuery().set_id[0]);
                totalGift.quantity = Number(quantity);

                let allInserts = [];

                addModBtns.forEach(btn=>{
                    if(btn.checked) {
                        const modification = btn.dataset.mod;
                        if(modification == 'inserts') {
                            allInserts.push(parseInt(btn.dataset.id));
                            totalGift[modification] = allInserts;
                        } else {
                            totalGift[modification] = Number(btn.dataset.id);
                        };
                    };
                });

                const totalGiftArray = [totalGift];
                setGiftItemsCookie(totalGiftArray);
            } else if (modal.dataset.modalForm == 'index-form') {
                const calcBundleCard = document.querySelector('.calc-bundle-card');
                const addModTitles = calcBundleCard.querySelectorAll('.__add_mod_title');
                const quantity = calcBundleCard.querySelector('.button-counter').querySelector('span').innerText;

                const totalGift = {};

                totalGift.quantity = Number(quantity);

                addModTitles.forEach(title=>{
                    const modification = title.dataset.mod;
                    const value = title.dataset.value;

                    if(modification == 'packing' && !value || modification == 'packing' && value == '') {
                        const card = getBundleCardByBtn('package-modal');
                        modal.dataset.modalState = "close";
                        document.documentElement.style.overflow = null;
                        card.classList.add('error');
                    } else if(modification == 'packing' && value) {
                        const card = getBundleCardByBtn('package-modal');
                        card.classList.remove('error');
                    }
                    if(modification == 'candySet' && !value || modification == 'candySet' && value == '') {
                        const card = getBundleCardByBtn('consist-modal');
                        card.classList.add('error');
                        modal.dataset.modalState = "close";
                        document.documentElement.style.overflow = null;
                    } else if(modification == 'candySet' && value) {
                        const card = getBundleCardByBtn('consist-modal');
                        card.classList.remove('error');
                    }
                    

                    if(modification == 'inserts' && value && value !== '') {
                        totalGift[modification] = [Number(title.dataset.value)];
                    } else if(value && value !== '') {
                        totalGift[modification] = Number(title.dataset.value);
                    };
                });

                const totalGiftArray = [totalGift];
                setGiftItemsCookie(totalGiftArray);
            } else if (modal.dataset.modalForm == 'basket-form') {
                const container = document.querySelector('.basket-section__container');
                const orderCards = container.querySelectorAll('.basket-order-card');
                if(orderCards.length > 0) {

                    const totalGiftArray = [];
                    orderCards.forEach(card=>{
                        const btnChecked = card.querySelectorAll('.__card_to_delete');
                        const quantity = card.querySelector('.button-counter').querySelector('span').innerText;

                        let cardChecked = false;
                        btnChecked.forEach(btn=>{
                            if(btn.checked) cardChecked = true;
                        });

                        if(cardChecked) {
                            const id = parseInt(card.dataset.id);
                            const basket = getBasketFromCookie();
                            basket.forEach(item=>{
                                if(item.id == id) {

                                    const totalGift = {};
                                    totalGift.quantity = parseInt(quantity);
                                    for (let [key, value] of Object.entries(item)) {
                                        if(key !== 'id' && key !== 'quantity') totalGift[key] = value;
                                    };
                                    totalGiftArray.push(totalGift);
                                };
                            });
                        };
                    });

                    setGiftItemsCookie(totalGiftArray);
                };
            };
        });


        const paymentMethod = modal.querySelector('#payment-method');
        if(paymentMethod) {
            const fieldAccordion = modal.querySelector('.field_accordion');
            const selector = modal.querySelector('.field__selector');
            const selectVariants = selector.querySelectorAll('input[type="radio"]');
            const payItem = modal.querySelector('.pay-item');

            paymentMethod.addEventListener('click',()=>{
                if(fieldAccordion.classList.contains('show')) {
                    fieldAccordion.classList.remove('show');
                    selector.style.maxHeight = null;
                } else {
                    fieldAccordion.classList.add('show');
                    selector.style.maxHeight = selector.scrollHeight * 2 + 'px';
                }
            });
            selectVariants.forEach(elem=>{
                elem.addEventListener('click',()=>{
                    selectVariants.forEach(variant=>{
                        const label = document.querySelector(`label[for="${variant.id}"]`);
                        if(variant.checked) {
                            paymentMethod.dataset.selected = variant.dataset.type;
                            if(variant.dataset.type == 'bank_transfer') {
                                payItem.style.display = 'block';
                            } else {
                                payItem.style.display = 'none';

                                const titleBlock = payItem.querySelector('.size');
                                const fileInput = payItem.querySelector('#file-input');

                                titleBlock.innerHTML = 'Файл не выбран';
                                fileInput.value= null;
                            };

                            label.classList.add("active");
                            paymentMethod.value = label.innerText;
                            
                            let event = new Event('input', {bubbles: true});
                            paymentMethod.dispatchEvent(event);
                        } else {
                            label.classList.remove("active");
                        };
                    });

                    fieldAccordion.classList.remove('show');
                    selector.style.maxHeight = null;
                });
            });
            const fileInput = payItem.querySelector('#file-input');
            if(fileInput) {
                fileInput.addEventListener('change',()=>{
                    const titleBlock = payItem.querySelector('.size');
                    if(fileInput.files.item(0)) {
                        const name = fileInput.files.item(0).name;
                        const bytes = fileInput.files.item(0).size;
                        const kilobytes = bytes / 1000;
                        const megabytes = kilobytes / 1000;

                        let finalSize = bytes;
                        if(bytes < 100) {
                            const bytesStr = bytes.toString();
                            finalSize = bytesStr.substring(0, 4) + 'B';
                        } else if(bytes >= 100 && kilobytes < 100) {
                            const kilobytesStr = kilobytes.toString();
                            finalSize = kilobytesStr.substring(0, 4) + 'KB';
                        } else if (kilobytes >= 100) {
                            const megabytesStr = megabytes.toString();
                            finalSize = megabytesStr.substring(0, 4) + 'MB';
                        }

                        titleBlock.innerHTML = name + `   ${finalSize}`;
                    } else {
                        titleBlock.innerHTML = 'Файл не выбран';
                    };
                });
            };
        };

        const cancelBtn = modal.querySelector('.__cancel_all_btn');
        if(cancelBtn) {
            cancelBtn.addEventListener('click',()=>{
                const form = modal.querySelector('form');
                const formInputs = form.querySelectorAll('input');
                const payItem = form.querySelector('.pay-item');
                formInputs.forEach(input=>{
                    input.value = '';
                    payItem.style.display = 'none';
                });
            });
        };
    };
};

function getBundleCardByBtn(btnName) {
    const bundleCards = document.querySelectorAll('.bundle-card');
    let findedCard = '';
    bundleCards.forEach(card=>{
        const packageModalBtn = card.querySelector(`.open-modal[data-type-modal="${btnName}"]`);
        if(packageModalBtn) {
            findedCard = card;
        };
    });
    return findedCard;
};