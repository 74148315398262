export function showAll() {
    const showAllBtn = document.querySelectorAll('.__show_all_btn');
    if(showAllBtn.length > 0) {
        showAllBtn.forEach(btn=>{
            btn.addEventListener('click',()=>{
                const showAllBlock = document.querySelector('.__show_all_block');
                if(showAllBlock.classList.contains('active')) {
                    showAllBlock.classList.remove('active');
                    btn.innerText = 'Смотреть все';
                } else {
                    showAllBlock.classList.add('active');
                    btn.innerText = 'Свернуть';
                }
            });
        });
    };
}
