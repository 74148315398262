import { calculateTotalPrice } from "../pages/main/calcBundleCard.js";

export function attachmentsModal() {
    const modal = document.querySelector('[data-type=attachments-modal]');
    if(modal) {
        modal.querySelectorAll('.__add_mod_btn').forEach(btn=>{
            btn.addEventListener('click',()=>{
                setTimeout(() => {
                    calculateTotalPrice()
                }, 100);
            });
        });
    };
}