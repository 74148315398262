import { debounce, getCurrentQuery } from "./utils.js";

export function filters() {
    
    let filtersArray = [];

    // Проверка на активные фильтры и пуш их в массив

    const activeFilters = window.location.search.substring(1).split('&');
    activeFilters.forEach(filter=>{
        const activeFilterObj = {};
        const key = filter.split('=')[0];
        const value = filter.split('=')[1];
        activeFilterObj.key = key;
        activeFilterObj.value = value;
        filtersArray.push(activeFilterObj);
    });

    // Фильтры на input[type="checkbox"] и input[type="radio"]

    let filterElems = document.querySelectorAll('.__filter__elem');

    if(window.innerWidth < 769) filterElems = document.querySelectorAll('.__filter__elem_mobile');

    if(filterElems.length > 0) {
        filterElems.forEach(filter=>{
            const activeQueries = getCurrentQuery();
            const activeKey = filter.getAttribute('data-query');
            const activeValue = filter.getAttribute('data-value');
            if(activeQueries[`${activeKey}`]) {
                activeQueries[`${activeKey}`].forEach(value=>{
                    if(activeValue == value) filter.checked = true;
                });
                activeQueries[`${activeKey}`].forEach(value=>{
                    if(activeValue == value) {
                        const filterId = filter.id;
                        const labels = document.querySelectorAll(`label[for="${filterId}"]`);
                        labels.forEach(label=>{
                            label.classList.add('active');
                        });
                    } else if(activeValue !== value) {
                        const filterId = filter.id;
                        const labels = document.querySelectorAll(`label[for="${filterId}"]`);
                        labels.forEach(label=>{
                            label.classList.remove('active');
                        });
                    };
                    if(activeValue == value) filter.checked == true;
                });
            };
            
            filter.addEventListener('click',()=>{
                filtersArray = [];
                const rangeFilterText = document.querySelectorAll('.__filter_range__text');
                if(rangeFilterText.length > 0) {
                    pushFilterInArray(filtersArray, rangeFilterText);
                }
                filterElems.forEach(elem=>{
                    if(elem.checked) {
                        console.log(elem);
                        const candyTypeObj = {};
                        filtersArray.push({
                            key: elem.dataset.query,
                            value: elem.dataset.value
                        });
                    } 
                    // else {
                    //     filtersArray = filtersArray.filter(filter => !(filter.key === clickedKey && filter.value === clickedValue));
                    // };
                });

                console.log(filtersArray);
                
                if(filter.type == 'radio') {
                    const radioKey = filter.getAttribute('data-query');
                    const radioValue = filter.getAttribute('data-value');
                    filterElems.forEach(elem=>{
                        const labels = document.querySelectorAll(`label[for="${elem.id}"]`);
                        const elemKey = filter.getAttribute('data-query');
                        const elemValue = elem.getAttribute('data-value');
                        if(elemKey == radioKey && radioValue == elemValue) {
                            labels.forEach(label=>{
                                label.classList.add('active');
                            });
                        } else if(elemKey == radioKey && radioValue !== elemValue) {
                            labels.forEach(label=>{
                                label.classList.remove('active');
                            });
                        }
                    });
                };
                // debouncedFilterWithArray(filtersArray);
            });
        });
    };

    // Фильтры типа min/max

    const rangeFilterRange = document.querySelectorAll('.__filter_range__range');
    const rangeFilterText = document.querySelectorAll('.__filter_range__text');
    if(rangeFilterRange.length > 0) {
        rangeFilterRange.forEach(range=>{
            filtersArray.forEach(filter=>{
                if(filter.key == 'max_price') {
                    range.value = filter.value;

                    let event = new Event("input", {bubbles: true});

                    setTimeout(() => {
                        range.dispatchEvent(event);
                    }, 1500);
                };
            });

            range.addEventListener('input',()=>{
                debouncedChangeTextsValue(range);

                debouncedPushFilterInArray(filtersArray, rangeFilterText);
            });
        });
    };
    if(rangeFilterText.length > 0) {
        rangeFilterText.forEach(inputTypedIn=>{
            inputTypedIn.addEventListener('input',()=>{
                inputTypedIn.value = inputTypedIn.value.replace(/[^0-9+]/g, ''); 
                debouncedReplaceNumbers(inputTypedIn);
                debouncedCheckForMaxValue();

                debouncedChangeRangeValue();

                pushFilterInArray(filtersArray, rangeFilterText);
            });
        });
    };

    // Кнопки принятия и отмены фильтрации

    const filterAcceptBtns = document.querySelectorAll('.__filter__accept_btn');
    if(filterAcceptBtns.length > 0) {
        filterAcceptBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                filterWithArray(filtersArray);
            });
        });
    };
    const filterCancelBtns = document.querySelectorAll('.__filter__cancel_btn');
    if(filterCancelBtns.length > 0) {
        filterCancelBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                const url = new URLSearchParams();
                window.location.search = url.toString();
            });
        });
    };



    document.addEventListener('DOMContentLoaded', function() {
        const dates = document.querySelectorAll('.date');
        if (dates.length > 0) {
          dates[0].classList.add('first');
        }
    });
}

// Фильтры типа min/max

const debouncedChangeTextsValue = debounce(changeTextsValue, 1200);
function changeTextsValue(range) {
    const rangeFilterText = document.querySelectorAll('.__filter_range__text');
    const value = range.value;
    let minInputValue = '';
    let maxInputValue = value;
    rangeFilterText.forEach(input=>{
        if(inputIsMin(input) && input.value == '') {
            input.value = range.min;
        } else if(inputIsMin(input)) {
            minInputValue = input.value;
        } else if(inputIsMax(input)) {
            input.value = value;
        };
    });
    if(Number(minInputValue) > Number(maxInputValue)) {
        rangeFilterText.forEach(input=>{
            if(inputIsMin(input)) input.value = Number(maxInputValue) - 1;
        });
    };
};

const debouncedReplaceNumbers = debounce(replaceNumbers, 500);
function replaceNumbers(input) {
    if(Number(input.value) < input.min){
        input.value = input.min;
    } else if(Number(input.value) > input.max) {
        input.value = input.max;
    };
};

const debouncedChangeRangeValue = debounce(changeRangeValue, 600);
function changeRangeValue() {
    const rangeFilterRange = document.querySelectorAll('.__filter_range__range');
    const rangeFilterText = document.querySelectorAll('.__filter_range__text');

    let maxValue = '';
    rangeFilterText.forEach(input=>{
        if(inputIsMax(input)) {
            maxValue = input.value;
        } else if(inputIsMin(input)) {
            maxValue = input.value;
        }
    });
    rangeFilterRange.forEach(range=>{
        range.value = maxValue;
        let event = new Event("input", {bubbles: true});

        range.dispatchEvent(event);
    });
};

const debouncedCheckForMaxValue = debounce(checkForMaxValue, 500);
function checkForMaxValue() {
    const rangeFilterText = document.querySelectorAll('.__filter_range__text');
    let minInputValue = '';
    let maxInputValue = '';
    rangeFilterText.forEach(input=>{
        if(inputIsMin(input)) {
            minInputValue = input.value;
        }
        if(inputIsMax(input)) {
            maxInputValue = input.value;
        };
    });

    if(Number(maxInputValue) <= Number(minInputValue)) {
        rangeFilterText.forEach(input=>{
            if(inputIsMax(input)) input.value = Number(minInputValue) + 1;
        });
    };
};

const debouncedPushFilterInArray = debounce(pushFilterInArray, 600);
function pushFilterInArray(array, rangeFiltersText) {
    rangeFiltersText.forEach(input=>{
        const filterObject = {};
        if(inputIsMin(input)) {
            filterObject.key = 'min_price';
            filterObject.value = input.value;
        } else if(inputIsMax(input)) {
            filterObject.key = 'max_price';
            filterObject.value = input.value;
        };
        array.forEach(filter=>{
            if(filter.key == filterObject.key) {
                const indexOfOldKey = array.indexOf(filter);
                array.splice(indexOfOldKey, 1);
            }
        })
        array.push(filterObject);
    });
}

function inputIsMin(input) {
    let isMin = false;
    if(input.classList.contains('__filter_range__text_min')) isMin=true;
    return isMin;
};
function inputIsMax(input) {
    let isMax = false;
    if(input.classList.contains('__filter_range__text_max')) isMax=true;
    return isMax;
};

// Стандартная функция фильтрации

function filterWithArray(Array) {
    const url = new URLSearchParams();
    Array.forEach(elem=>{        
        url.append(elem.key, elem.value);
    });
    window.location.search = url.toString();
}